<template>
     <v-main class="py-0">
          <v-flex xs12 sm12 offset-sm0>
                <router-view />
          </v-flex>
     </v-main>
</template>

<script>
export default {
    name:'MainContent',

}
</script>

<style>

</style>